import React from "react"

import { slugify } from "../functions/slugify"

export default ({ id, offset }) => (
  <div
    id={slugify(id)}
    style={{
      display: "block",
      position: "relative",
      top: offset ? offset : "-120px", // Sticky navbar height = 60px
      visibility: "hidden",
    }}
  />
)
