import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { TriangleTopRight } from "../modules/Triangles"

export default ({ link, fluid, title, label }) => (
  <Link to={link} className="uk-link-reset uk-transition-toggle">
    <div className="uk-transition-scale-up uk-transition-opaque">
      <div className="uk-card uk-card-default uk-box-shadow-hover-large">
        <div className="uk-card-media-top uk-position-relative">
          <Img
            fluid={{
              ...fluid,
              aspectRatio: 16 / 9,
            }}
            data-uk-img
          />

          {label && <TriangleTopRight Label={label} />}

          {/* <div
            className="uk-position-bottom-right uk-position-small uk-background-secondary uk-light uk-text-small uk-text-uppercase"
            style={{ paddingLeft: "6px", paddingRight: "6px" }}
          >
            <small>{label}</small>
          </div> */}
        </div>
        <div className="uk-card-small uk-card-body uk-text-truncate">
          {title}
          <div
            className="uk-position-bottom-right uk-button-text"
            style={{ paddingRight: "5px" }}
            
          >
            <span data-uk-icon="chevron-double-right"/>
            <small>découvrir...</small>
          </div>
        </div>
      </div>
    </div>
  </Link>
)
