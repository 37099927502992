import React, { Fragment } from "react"
import Img from "gatsby-image"

import PlayIcon from "../modules/PlayIcon"
import Anchor from "../modules/Anchor"

export default ({ date, text, youtube, thumb }) => (
  <Fragment>
    <div className="uk-animation-fade">
      <p className="uk-text-center uk-text-muted">
        <span data-uk-icon="calendar" className="uk-margin-small-right"></span>
        {date}
      </p>

      {text && (
        <div className="uk-text-justify">
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
      )}
    </div>

    <Anchor id={"video"} />

    {youtube && (
      <div className="uk-flex uk-flex-center">
        <div
          data-uk-lightbox
          className="uk-link-reset uk-transition-toggle uk-margin-remove uk-margin-top uk-width-1-1 uk-width-1-2@s uk-width-1-1@m uk-width-1-2@l"
        >
          <a
            href={`https://www.youtube-nocookie.com/embed/${youtube}?autoplay=1&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=1`}
            data-type="iframe"
          >
            <div className="uk-transition-scale-up uk-transition-opaque">
              <div className="uk-box-shadow-hover-large">
                <div className="uk-inline uk-light uk-width-1-1">
                  {thumb ? (
                    <Img
                      fluid={{
                        ...thumb.fluid,
                        aspectRatio: 16 / 9,
                      }}
                      className="image-thumb uk-box-shadow-medium"
                    />
                  ) : (
                    <img
                      src={`http://img.youtube.com/vi/${youtube}/maxresdefault.jpg`}
                      alt=""
                    />
                  )}

                  <div className="uk-position-center uk-transition-fade uk-width-1-4 uk-width-1-5@s uk-padding-small">
                    <PlayIcon />
                  </div>
                </div>
              </div>
              <div className="uk-flex uk-flex-right">
                {/* <span
                  uk-overlay-icon="icon: play"
                  className="uk-padding-small"
                ></span>
                Visionner la vidéo */}
                <div className="uk-button-text">
                  <span data-uk-icon="play" />
                  <small>visionner la vidéo...</small>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    )}
  </Fragment>
)
