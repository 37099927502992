import React from "react"
import { Link } from "gatsby"

export default ({ url }) => (
  <div className="uk-flex uk-flex-center">
    <Link to={url} className="uk-link-heading">
      <span data-uk-icon="icon: chevron-left; ratio: 2.5" />
    </Link>
    &nbsp;
  </div>
)
