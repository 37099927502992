import React, { Component, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import Recaptcha from "react-google-recaptcha"
import moment from "moment"
import uuid62 from "uuid62"

import Context from "../components/Context"

import {
  sendEmail,
  createContentfulEntry,
  addContentfulEntryChildren,
} from "../functions/apiLambda"

// Entry params
const defaultFieldsData = {
  author: "",
  email: "",
  message: "",
}

class Comments extends Component {
  state = {
    recaptcha: null,
  }

  componentDidMount() {
    // console.log("comments", this.props.comments)

    if (new RegExp("localhost").test(window.location.origin)) {
      // Ignore reCaptcha locally
      this.setState({ recaptcha: "localhost" })
    }

    if (typeof window !== "undefined") {
      this.setState({ url: window.location.origin + window.location.pathname })
    }
  }

  componentDidUpdate(prevProps) {
    const { comments, reference } = this.props

    // console.log("comments", comments)

    if (prevProps.comments !== comments) {
      // COMMENTS INIT

      // Init comment submit if state not defined
      if (!this.state[reference]) {
        this.setState({
          [reference]: {
            submit: null,
            isLoading: false,
            entryFieldsData: defaultFieldsData,
          },
        })
      }
      // Hide submit message after refresh
      else {
        this.setState({
          [reference]: {
            ...this.state[reference],
            submit: null,
          },
        })
      }

      // END : COMMENTS INIT

      // SUB COMMENTS INIT

      comments &&
        comments.forEach(comment => {
          // Init comment submit if state not defined
          if (!this.state[comment.id]) {
            this.setState({
              [comment.id]: {
                submit: null,
                isLoading: false,
                entryFieldsData: defaultFieldsData,
              },
            })
          }
          // Hide submit message after refresh
          else {
            this.setState({
              [comment.id]: {
                ...this.state[comment.id],
                submit: null,
              },
            })
          }
        })

      // END : SUB COMMENTS INIT
    }
  }

  handleChange = reference => e => {
    this.setState({
      [reference]: {
        ...this.state[reference],
        entryFieldsData: {
          ...this.state[reference].entryFieldsData,
          [e.target.name]: e.target.value,
        },
      },
    })
  }

  handleRecaptcha = value => {
    this.setState({ recaptcha: value })
  }

  handleSubmit = reference => e => {
    e.preventDefault() // prevent submit form

    this.setState({
      [reference]: {
        ...this.state[reference],
        isLoading: true,
        submit: null, // reset previous submit error
      },
    })

    const entryContentType = "comment"
    const entryId = uuid62.v4()
    const entryFieldsData = {
      ...this.state[reference].entryFieldsData,
      date: moment().format(),
    }
    const referenceId = reference
    const referenceLinkField = "comments"

    // console.log("entryFieldsData", entryFieldsData)
    // console.log("referenceId", referenceId)

    // Add Contentful entry
    createContentfulEntry(
      entryContentType,
      entryId,
      entryFieldsData,
      referenceId,
      referenceLinkField
    )
      .then(response => {
        // console.log("response : ", response)
        if (response.status === 200) {
          // SUCCESS
          this.setState({
            [reference]: {
              ...this.state[reference],
              isLoading: false,
              submit: "success",
            },
          })

          const formCommentAuthor = this.state[reference].entryFieldsData.author
          const formCommentDate = moment(
            this.state[reference].entryFieldsData.date
          ).format("DD/MM/YYYY HH:mm")
          const formCommentEmail = this.state[reference].entryFieldsData.email
          const formCommentMessage = this.state[reference].entryFieldsData
            .message

          const { appName, blogAuthor, blogEmail } = this.props

          // Send admin email
          if (formCommentEmail !== blogEmail) {
            let mailFrom = `"${appName}" <${blogEmail}>` // verified AWS email adress
            let mailReplyTo = `"${formCommentAuthor}" <${formCommentEmail}>`
            let mailTo = `"${blogAuthor} (${appName})" <${blogEmail}>`
            let mailSubject = `Nouveau commentaire de ${formCommentAuthor}`
            let mailBody = `
            <html>
              <head>
              <title>${mailSubject}</title>
              </head>
              <body>
                <p>
                  Un nouveau commentaire a été posté sur ${appName} :<br />
                  <a href="${this.state.url}#commentaires" target="_blank">${this.state.url}#commentaires</a>
                </p>
                <p>COMMENTAIRE</p>
                <p>
                  <b>Auteur</b> : ${formCommentAuthor}<br />
                  <b>Date</b> : ${formCommentDate}<br />
                  <b>Email</b> : ${formCommentEmail}<br />
                  <b>Message</b> : ${formCommentMessage}
                </p>
                <p>
                --
                </p>
                <p><em>Ceci est un message automatique envoyé via ${appName}</em></p>
              </body>
            </html>
          `
            sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody)
              .then(response => response.json())
              .then(data => {
                console.log("sendEmail to : ", data)
              })
          }
          // END : Send admin email

          // Send author email (on comment response)

          const { allComments } = this.context.data

          if (allComments) {
            let comment = allComments.find(
              comment => comment.id === referenceId
            )
            // console.log("comment ==> ", comment)
            if (comment) {
              const subComments = comment.comments
                ? Object.values(comment.comments)
                : []
              let emailsList = []

              // Add sub comments if exist
              if (subComments.length > 0) {
                subComments.forEach(subComment =>
                  emailsList.push(subComment.email)
                )
                // console.log("emailsList", emailsList)
              }
              if (emailsList.length === subComments.length) {
                // Add initial comment email
                emailsList.push(comment.email)
                console.log("Add initial comment email ==> ", emailsList)

                // Remove form submit email and admin email
                const recipientEmailsList = emailsList
                  .filter(email => email !== formCommentEmail)
                  .filter(email => email !== blogEmail)
                console.log(
                  "Remove form submit email and admin email ==> ",
                  recipientEmailsList
                )

                // Filter duplicated emails
                const uniqueEmailsList = recipientEmailsList.filter(
                  (item, email) => recipientEmailsList.indexOf(item) === email
                )
                console.log("Filter duplicated emails ==> ", uniqueEmailsList)

                uniqueEmailsList.forEach(email => {
                  console.log("send email to ==> ", email)

                  // Send email to each author
                  let mailFrom = `"${blogAuthor} (${appName})" <${blogEmail}>` // verified AWS email adress
                  let mailReplyTo = `"${blogAuthor} (${appName})" <${blogEmail}>`
                  let mailTo = `<${email}>`
                  let mailSubject = `${formCommentAuthor} a répondu à votre commentaire`
                  let mailBody = `
                    <html>
                      <head>
                      <title>${mailSubject}</title>
                      </head>
                      <body>
                        <p>
                          Un nouveau commentaire a été posté en réponse à votre message sur ${appName} :<br />
                          <a href="${this.state.url}#commentaires" target="_blank">${this.state.url}#commentaires</a>
                        </p>
                        <p>COMMENTAIRE</p>
                        <p>
                          <b>Auteur</b> : ${formCommentAuthor}<br />
                          <b>Date</b> : ${formCommentDate}<br />
                          <b>Message</b> : ${formCommentMessage}
                        </p>
                        <p>
                        --
                        </p>
                        <p><em>Ceci est un message automatique envoyé via ${appName}</em></p>
                      </body>
                    </html>
                  `
                  sendEmail(
                    mailFrom,
                    mailReplyTo,
                    mailTo,
                    mailSubject,
                    mailBody
                  )
                    .then(response => response.json())
                    .then(data => {
                      console.log("sendEmail to : ", data)
                    })

                  // END : Send email to each author
                })
              }
            }
          }

          // END : Send author email (on comment response)

          // ==================================================================================== //

          // Reset sent data
          this.setState({
            [reference]: {
              ...this.state[reference],
              entryFieldsData: defaultFieldsData,
            },
          })
        } else {
          // ERROR
          this.setState({
            [reference]: {
              ...this.state[reference],
              isLoading: false,
              submit: "error",
            },
          })
        }
        return response.json()
      })
      .then(data => {
        // console.log("reloadAllComments")
        this.context.set({ reloadAllComments: true })

        return data
      })
      .then(data => {
        // console.log("DATA ==> ", data)
      })
    // END : Add Contentful entry
  }

  render() {
    const { comments, reference } = this.props

    const renderComment = comment => (
      <Fragment>
        <header className="uk-comment-header">
          <div className="uk-grid-small uk-flex-middle" data-uk-grid>
            <div className="uk-width-auto">
              <span data-uk-icon="icon: user; ratio: 2" />
            </div>
            <div className="uk-width-expand">
              <h4 className="uk-comment-title uk-margin-remove">
                {comment.author}
              </h4>
              <p className="uk-comment-meta uk-margin-remove-top">
                {moment(comment.date).format("DD/MM/YYYY HH:mm")}
              </p>
            </div>
          </div>
        </header>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="uk-comment-body uk-padding-small"
          // dangerouslySetInnerHTML={{
          //   __html: comment.message.childMarkdownRemark.html,
          // }}
        >
          {comment.message}
        </div>
      </Fragment>
    )

    const renderForm = reference => (
      <Fragment>
        {this.state[reference] && this.state[reference].submit === "success" && (
          <div className="uk-text-center">
            <div className="uk-alert-success" data-uk-alert>
              <div className="uk-flex uk-flex-middle uk-flex-center">
                <span
                  className="uk-flex-none uk-margin-small-right"
                  data-uk-icon="check"
                />
                <strong>Votre message a bien été envoyé</strong>
              </div>
            </div>
          </div>
        )}

        {(this.state[reference] && this.state[reference].submit) ===
          "error" && (
          <div className="uk-text-center">
            <div className="uk-alert-danger" data-uk-alert>
              <div className="uk-flex uk-flex-middle uk-flex-center">
                <span
                  className="uk-flex-none uk-margin-small-right"
                  data-uk-icon="warning"
                />
                <strong>
                  Une erreur est survenue lors de l'envoi de votre message...
                </strong>
              </div>
            </div>
          </div>
        )}

        {this.state[reference] && (
          <form method="post" onSubmit={this.handleSubmit(reference)}>
            <fieldset className="uk-fieldset">
              <div
                className="uk-child-width-expand@s uk-grid-small uk-margin"
                data-uk-grid
              >
                <div>
                  <input
                    className="uk-input"
                    type="text"
                    placeholder="Votre nom..."
                    name="author"
                    value={this.state[reference].entryFieldsData.author}
                    onChange={this.handleChange(reference)}
                    required
                  />
                </div>
                <div>
                  <input
                    className="uk-input"
                    type="email"
                    placeholder="Votre email..."
                    name="email"
                    value={this.state[reference].entryFieldsData.email}
                    onChange={this.handleChange(reference)}
                    required
                  />
                </div>
              </div>

              <div className="uk-margin">
                <textarea
                  className="uk-textarea"
                  rows="5"
                  placeholder="Votre message..."
                  name="message"
                  value={this.state[reference].entryFieldsData.message}
                  onChange={this.handleChange(reference)}
                  required
                />
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="recaptcha">
                  Anti-spam
                </label>
                <Recaptcha
                  ref="recaptcha"
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  onChange={this.handleRecaptcha}
                />
              </div>

              {!this.state[reference].isLoading && (
                <button
                  type="submit"
                  className="uk-button uk-button-default"
                  disabled={this.state.recaptcha ? false : true}
                >
                  Envoyer
                </button>
              )}
              {this.state[reference].isLoading && (
                <Fragment>
                  <span
                    data-uk-spinner
                    className="uk-margin-small-left uk-margin-small-right"
                  />
                  Envoi en cours...
                </Fragment>
              )}
            </fieldset>
          </form>
        )}
      </Fragment>
    )

    return (
      <div>
        {false && (
          <div className="uk-container">
            <textarea
              className="uk-textarea"
              rows="10"
              placeholder="Copier-Coller JSON..."
              onChange={e => {
                this.setState({ json: JSON.parse(e.target.value) })
              }}
            />

            {this.state.json && (
              <button
                type="submit"
                className="uk-button uk-button-primary"
                onClick={() => {
                  const allComments = this.state.json.results.comments
                  console.log("allComments", allComments)

                  const comments = allComments.filter(
                    comment => !comment.reply_of
                  )
                  // const subComments = allComments.filter(
                  //   comment => comment.reply_of
                  // )
                  // console.log("comments", comments)
                  // console.log("subComments", subComments)

                  // COMMENTS

                  let commentsIds = []

                  comments.forEach(comment => {
                    // console.log("comment", comment)
                    const commentId = uuid62.v4()
                    commentsIds = [...commentsIds, commentId]

                    console.log("commentsIds.length", commentsIds.length)
                    console.log("comments.length", comments.length)

                    const entryContentType = "comment"
                    const entryId = commentId
                    const entryFieldsData = {
                      author: comment.pseudo ? comment.pseudo : comment.login,
                      email: "contact@guslyon.fr",
                      date: moment(comment.date).format(),
                      //eslint-disable-next-line
                      message: comment.content.replace(/<br\s*[\/]?>/gi, " "),
                    }
                    const referenceId = reference
                    const referenceLinkField = "comments"

                    // console.log("entryId", entryId)
                    // console.log("entryFieldsData", entryFieldsData)
                    // console.log("referenceId", referenceId)

                    if (
                      entryContentType &&
                      entryId &&
                      entryFieldsData &&
                      referenceId &&
                      referenceLinkField
                    ) {
                      // Create Contentful entry (comment)

                      createContentfulEntry(
                        entryContentType,
                        entryId,
                        entryFieldsData
                      ).then(response => {
                        // console.log("response : ", response)
                        if (response.status === 200) {
                          // success
                          // console.log("SUCCESS : create comment", entryFieldsData)
                          // console.log(
                          //   "===================== NEW ENTRY =====================",
                          //   entryFieldsData
                          // )

                          const subComments = allComments.filter(
                            subComment => subComment.reply_of === comment.id
                          )
                          console.log("subComments", subComments)

                          // SUB COMMENTS

                          let subCommentsIds = []

                          subComments.forEach(subComment => {
                            // console.log("comment", comment)
                            const subCommentId = uuid62.v4()
                            subCommentsIds = [...subCommentsIds, subCommentId]

                            const entryContentType = "comment"
                            const entryId = subCommentId
                            const entryFieldsData = {
                              author: subComment.pseudo
                                ? subComment.pseudo
                                : subComment.login,
                              email: "contact@guslyon.fr",
                              date: moment(subComment.date).format(),

                              message: subComment.content.replace(
                                //eslint-disable-next-line
                                /<br\s*[\/]?>/gi,
                                " "
                              ),
                            }
                            const referenceId = commentId
                            const referenceLinkField = "comments"

                            // Create Contentful entry (sub comment)
                            if (
                              entryContentType &&
                              entryId &&
                              entryFieldsData &&
                              referenceId &&
                              referenceLinkField
                            ) {
                              createContentfulEntry(
                                entryContentType,
                                entryId,
                                entryFieldsData
                              ).then(response => {
                                // console.log("response : ", response)
                                if (response.status === 200) {
                                  // success
                                  console.log(
                                    "SUCCESS : create sub comment",
                                    entryFieldsData
                                  )
                                } else {
                                  // error
                                  console.log(
                                    "ERROR : create sub comment",
                                    entryFieldsData
                                  )
                                }
                                return response.json()
                              })
                            }

                            // END : Create Contentful entry (sub comment)

                            if (subCommentsIds.length === subComments.length) {
                              const entryId = referenceId
                              const childrenField = referenceLinkField
                              const childrenIds = subCommentsIds
                              console.log("entryId", entryId)
                              console.log("childrenField", childrenField)
                              console.log("childrenIds", childrenIds)

                              addContentfulEntryChildren(
                                entryId,
                                childrenField,
                                childrenIds
                              ).then(response => {
                                console.log(
                                  "addContentfulEntryChildren response : ",
                                  response
                                )
                                console.log("reloadAllComments")
                                this.context.set({ reloadAllComments: true })
                              })
                            }
                          })

                          // END : SUB COMMENTS
                        } else {
                          // error
                          console.log("ERROR : create comment", entryFieldsData)
                        }
                        return response.json()
                      })

                      // END : Create Contentful entry (comment)

                      if (commentsIds.length === comments.length) {
                        const entryId = referenceId
                        const childrenField = referenceLinkField
                        const childrenIds = commentsIds
                        console.log("entryId", entryId)
                        console.log("childrenField", childrenField)
                        console.log("childrenIds", childrenIds)

                        addContentfulEntryChildren(
                          entryId,
                          childrenField,
                          childrenIds
                        ).then(response => {
                          console.log(
                            "addContentfulEntryChildren response : ",
                            response
                          )
                          console.log("reloadAllComments")
                          this.context.set({ reloadAllComments: true })
                        })
                      }
                    }
                  })

                  // END : COMMENTS

                  console.log("reloadAllComments")
                  this.context.set({ reloadAllComments: true })
                }}
              >
                Importer
              </button>
            )}
          </div>
        )}

        {/* PARENTS */}

        {comments &&
          comments.map(comment => (
            <div
              key={comment.id}
              className="uk-card uk-card-secondary uk-card-body uk-margin"
            >
              {renderComment(comment)}

              <div className="uk-margin-large-left uk-margin">
                {/* CHILDREN */}

                {comment.comments && (
                  <div
                    className="uk-margin uk-child-width-1-1 uk-grid-medium"
                    data-uk-grid
                  >
                    {comment.comments.map(comment => (
                      <div key={comment.id}>{renderComment(comment)}</div>
                    ))}
                  </div>
                )}

                {/* END : CHILDREN */}
              </div>
              <div className="uk-text-right">
                <button
                  className="uk-button uk-button-link"
                  data-uk-toggle={`target: #com-${comment.id}; animation: uk-animation-fade`}
                >
                  <span
                    className="uk-margin-small-right"
                    data-uk-icon="comment"
                  ></span>{" "}
                  Répondre
                </button>
              </div>

              <div id={`com-${comment.id}`} className="uk-margin" hidden>
                {renderForm(comment.id)}
              </div>
            </div>
          ))}

        {/* END PARENT */}
        <div className="uk-card uk-card-secondary uk-card-hover uk-card-body uk-margin">
          <div className="uk-legend uk-margin">
            <span
              className="uk-margin-small-right"
              data-uk-icon="icon: commenting; ratio: 1.5"
            ></span>{" "}
            Ajouter un commentaire
          </div>
          {renderForm(reference)}
        </div>
      </div>
    )
  }
}

Comments.contextType = Context

export default ({ comments, reference }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      blog: contentfulBlog {
        user
        email
      }
    }
  `)
  return (
    <Fragment>
      <Comments
        comments={comments}
        reference={reference}
        appName={data.site.siteMetadata.title}
        blogAuthor={data.blog.user}
        blogEmail={data.blog.email}
      />
    </Fragment>
  )
}
