import React, { Component, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import moment from "moment"
import uuid62 from "uuid62"

import Context from "../components/Context"

import {
  sendEmail,
  findContentfulEntries,
  createContentfulEntry,
  getContentfulEntry,
  addContentfulEntryChild,
} from "../functions/apiLambda"

class Follow extends Component {
  state = {
    name: "",
    email: "",
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({ url: window.location.origin + window.location.pathname })
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = e => {
    e.preventDefault() // prevent submit form

    this.setState({
      ...this.state,
      isLoading: true,
      submit: null, // reset previous submit error
    })

    const entryContentType = "follower"
    const entryId = uuid62.v4()
    const entryFieldsData = {
      email: this.state.email,
      name: this.state.name,
      date: moment().format(),
    }
    const referenceId = this.props.reference
    const referenceLinkField = "followers"

    // console.log(entryContentType, entryFieldsData, referenceId, referenceLinkField)

    findContentfulEntries(entryContentType, "email", entryFieldsData.email)
      // .then(response => {
      //   console.log(response)
      //   return response
      // })
      .then(data => {
        // Create entry if email doesn't exist in database
        if (data.length === 0) {
          // Add Contentful entry
          createContentfulEntry(
            entryContentType,
            entryId,
            entryFieldsData,
            referenceId,
            referenceLinkField
          ).then(response => {
            // console.log("response : ", response)
            if (response.status === 200) {
              // SUCCESS
              this.setState({
                ...this.state,
                isLoading: false,
                submit: "success",
              })

              this.sendEmails()

              // ==================================================================================== //
            } else {
              // ERROR
              this.setState({
                ...this.state,
                isLoading: false,
                submit: "error",
              })
            }
            return response.json()
          })
          // .then(data => {
          // console.log("DATA ==> ", data)
          // })

          // END : Add Contentful entry
        }
        // Ignore creating entry if email exist in database : only update event entry ?
        else {
          // Find reference entry (by event id)
          getContentfulEntry(referenceId).then(event => {
            const followerId = data[0].id
            let followers = []
            if (event.followers) {
              followers = event.followers["fr-FR"]
            }
            const isFollowing = followers.some(
              follower => follower.sys.id === followerId
            )
            // console.log("followerId", followerId)
            // console.log("followers", followers)
            // console.log("isFollowing", isFollowing)

            if (isFollowing) {
              this.setState({
                ...this.state,
                isLoading: false,
                submit: "exist",
              })
            } else {
              addContentfulEntryChild(
                referenceId,
                "followers",
                followerId
              ).then(response => {
                console.log("addContentfulEntryChild response : ", response)
                // SUCCESS
                this.setState({
                  ...this.state,
                  isLoading: false,
                  submit: "success",
                })

                this.sendEmails()
              })
            }
          })
        }
      })
  }

  sendEmails = () => {
    const { appName, blogAuthor, blogEmail } = this.props

    const formFollowerName = this.state.name
    const formFollowerEmail = this.state.email
    const formFollowerDate = moment(this.state.date).format("DD/MM/YYYY HH:mm")

    // Send admin email
    const adminMailFrom = `"${appName}" <${blogEmail}>` // verified AWS email adress
    const adminMailReplyTo = `"${formFollowerName}" <${formFollowerEmail}>`
    const adminMailTo = `"${blogAuthor} (${appName})" <${blogEmail}>`
    const adminMailSubject = `${formFollowerName} s'est abonné à "${this.props.title}"`
    const adminMailBody = `
      <html>
        <head>
        <title>${adminMailSubject}</title>
        </head>
        <body>
          <p>
            Un nouvel utilisateur s'est abonné sur ${appName} :<br />
          </p>
          <p>
            <b>Voyage</b> : ${this.props.title}<br />
            <b>Lien</b> : <a href="${this.state.url}" target="_blank">${this.state.url}</a>
            <b>Nom</b> : ${formFollowerName}<br />
            <b>Email</b> : ${formFollowerEmail}<br />
            <b>Date</b> : ${formFollowerDate}<br />
          </p>
          <p>
          --
          </p>
          <p><em>Ceci est un message automatique envoyé via ${appName}</em></p>
        </body>
      </html>
    `
    sendEmail(
      adminMailFrom,
      adminMailReplyTo,
      adminMailTo,
      adminMailSubject,
      adminMailBody
    )
      .then(response => response.json())
      .then(data => {
        console.log("sendEmail to : ", data)
      })
    // END : Send admin email

    // Send follower email
    const userMailFrom = `"${blogAuthor} (${appName})" <${blogEmail}>` // verified AWS email adress
    const userMailReplyTo = `"${blogAuthor} (${appName})" <${blogEmail}>`
    const userMailTo = `"${formFollowerName}" <${formFollowerEmail}>`
    const userMailSubject = `Vous êtes abonné à "${this.props.title}"`
    const userMailBody = `
      <html>
        <head>
        <title>${userMailSubject}</title>
        </head>
        <body>
          <p>
            ${formFollowerName},
          </p>
          <p>
            Votre inscription a bien été envoyée...
          <br/>
            Vous recevrez une notification lorsqu'une nouvelle mise à jour du voyage "${this.props.title}" sera publiée sur ${appName}
          <br/>
            <a href="${this.state.url}" target="_blank">${this.state.url}</a>
          </p>
          <p>
            Pour annuler votre abonnement répondez "STOP" à ce message.
          <br/>
            Merci
          </p>
          <p>
          --
          </p>
          <p><em>Ceci est un message automatique envoyé via ${appName}</em></p>
        </body>
      </html>
    `
    sendEmail(
      userMailFrom,
      userMailReplyTo,
      userMailTo,
      userMailSubject,
      userMailBody
    )
      .then(response => response.json())
      .then(data => {
        console.log("sendEmail to : ", data)
      })
    // END : Send follower email
  }

  render() {
    return (
      <Fragment>
        {this.props.compact ? (
          <button
            className="uk-button uk-button-link uk-padding-remove"
            data-uk-toggle="target: #followModal"
          >
            <div className="uk-margin-left uk-margin-right">
              <span className="uk-visible@xl">S'abonner</span>
              <span
                data-uk-icon="icon: bell"
                className="uk-margin-small-left"
              />
            </div>
          </button>
        ) : (
          <button
            className="uk-button uk-button-primary uk-button-large uk-margin-large-top"
            data-uk-toggle="target: #followModal"
          >
            <div className="uk-margin-left uk-margin-right">
              <span
                data-uk-icon="icon: bell"
                className="uk-margin-small-right"
              />
              <span>S'abonner</span>
            </div>
          </button>
        )}

        <div id="followModal" className="uk-flex-top" data-uk-modal>
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-background-muted">
            <button
              className="uk-modal-close-outside"
              type="button"
              data-uk-close
            ></button>

            <h2 className="uk-modal-title">
              <span
                data-uk-icon="icon: bell; ratio: 1.5"
                className="uk-margin-right"
              />
              Suivre ce voyages ?
            </h2>

            <p>
              Pour recevoir une notification lorsqu'une nouvelle journée de
              voyage sera publiée, renseignez votre nom et votre email :
            </p>

            {this.state.submit === "success" && (
              <div className="uk-text-center">
                <div className="uk-alert-success" data-uk-alert>
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <span
                      className="uk-flex-none uk-margin-small-right"
                      data-uk-icon="check"
                    />
                    <strong>Votre inscription a bien été envoyée</strong>
                  </div>
                </div>
              </div>
            )}

            {this.state.submit === "error" && (
              <div className="uk-text-center">
                <div className="uk-alert-danger" data-uk-alert>
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <span
                      className="uk-flex-none uk-margin-small-right"
                      data-uk-icon="warning"
                    />
                    <strong>Une erreur est survenue lors de l'envoi...</strong>
                  </div>
                </div>
              </div>
            )}

            {this.state.submit === "exist" && (
              <div className="uk-text-center">
                <div className="uk-alert-warning" data-uk-alert>
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <span
                      className="uk-flex-none uk-margin-small-right"
                      data-uk-icon="happy"
                    />
                    <strong>Vous êtes déjà abonné aux notifications !</strong>
                  </div>
                </div>
              </div>
            )}

            {!["success", "exist"].includes(this.state.submit) && (
              <form method="post" onSubmit={this.handleSubmit}>
                <fieldset className="uk-fieldset">
                  <div
                    className="uk-child-width-expand@s uk-grid-small uk-margin"
                    data-uk-grid
                  >
                    <div>
                      <div className="uk-inline uk-width-1-1">
                        <span
                          className="uk-form-icon"
                          data-uk-icon="icon: user"
                        />
                        <input
                          className="uk-input"
                          type="text"
                          placeholder="Votre nom..."
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <div className="uk-inline uk-width-1-1">
                        <span
                          className="uk-form-icon"
                          data-uk-icon="icon: mail"
                        />
                        <input
                          className="uk-input"
                          type="email"
                          placeholder="Votre email..."
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="uk-text-right">
                    {this.state.isLoading ? (
                      <Fragment>
                        <span
                          data-uk-spinner
                          className="uk-margin-small-left uk-margin-small-right"
                        />
                        Envoi en cours...
                      </Fragment>
                    ) : (
                      <Fragment>
                        <button
                          className="uk-button uk-button-default uk-modal-close uk-margin-small-right"
                          type="button"
                        >
                          Annuler
                        </button>
                        <button
                          type="submit"
                          className="uk-button uk-button-secondary"
                        >
                          S'abonner
                        </button>
                      </Fragment>
                    )}
                  </div>
                </fieldset>
              </form>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

Follow.contextType = Context

export default ({ compact, reference, title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      blog: contentfulBlog {
        user
        email
      }
    }
  `)
  return (
    <Fragment>
      <Follow
        compact={compact}
        reference={reference}
        title={title}
        appName={data.site.siteMetadata.title}
        blogAuthor={data.blog.user}
        blogEmail={data.blog.email}
      />
    </Fragment>
  )
}
