import React, { Fragment } from "react"
import { Link } from "gatsby"

export default ({ prevSlug, prevTitle, nextSlug, nextTitle }) => {
  const width =
    !prevSlug || !nextSlug ? "uk-child-width-1-1" : "uk-child-width-1-2"

  return (
    <Fragment>
      {(prevSlug || nextSlug) && <hr className="uk-margin-medium-top" />}

      <div
        className={`uk-pagination uk-flex uk-flex-between uk-animation-fade ${width}`}
      >
        {prevSlug && (
          <Link to={prevSlug} className="uk-flex uk-flex-middle uk-flex-left">
            <span
              className="uk-flex-none uk-margin-small-right"
              data-uk-pagination-previous
            />
            <span className="uk-text-truncate">{prevTitle}</span>
          </Link>
        )}
        {nextSlug && (
          <Link to={nextSlug} className="uk-flex uk-flex-middle uk-flex-right">
            <span className="uk-text-truncate">{nextTitle}</span>
            <span
              className="uk-flex-none uk-margin-small-left"
              data-uk-pagination-next
            />
          </Link>
        )}
      </div>
    </Fragment>
  )
}
