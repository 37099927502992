
// Function Filter entry comments
export function filterCommentsByEntry(allComments, entry) {
  const language = "fr-FR"
  let entryComments = []
    entry.comments &&
      entry.comments[language].forEach(comment => {
        entryComments = [...entryComments, comment.sys.id]
      })
    // console.log("matchComments allComments ==> ", allComments)
    let matchComments = Object.keys(allComments)
      .filter(key => entryComments.includes(allComments[key].id))
      .reduce((obj, key) => {
        obj[key] = allComments[key]
        return obj
      }, [])
    matchComments = Object.values(matchComments)
    // console.log("matchComments ==> ", matchComments)
    return matchComments
}
// END : Function Filter entry comments