import React from "react"

export default props => (
  <div
    className="uk-flex uk-flex-center uk-animation-toggle"
    data-uk-parallax="opacity: 1,0; viewport: 0.3"
  >
    <a href={props.to} className="uk-link-heading uk-position-absolute">
      <span
        data-uk-icon="icon: arrow-down; ratio: 2"
        className="uk-animation-slide-top"
      />
    </a>
    <a href={props.to} className="uk-link-heading uk-position-absolute">
      <span
        data-uk-icon="icon: arrow-down; ratio: 2"
        className="uk-animation-slide-bottom uk-animation-reverse"
      />
    </a>
    &nbsp;
  </div>
)
