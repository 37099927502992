import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import Context from "../components/Context"
// import metadata from "../data/metadata.json"

export default class SEO extends Component {
  // componentDidMount() {
  //   if (typeof window !== "undefined") {
  //     this.setState({ url: window.location.origin + window.location.pathname })
  //   }
  // }

  render() {
    const { pageTitle, pageDescription, pageImage, pageUrl } = this.props
    // console.log("SEO this.props", this.props)

    const { metadata } = this.context.data

    const truncate = (str, max) => {
      // console.log("str.length", str.length)
      // console.log("max - 3", max - 3)
      return str.length <= max ? str : str.substring(0, max - 3) + "..."
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            siteImage: contentfulBlog {
              cover {
                fluid(maxWidth: 1920) {
                  src
                }
              }
            }
          }
        `}
        render={({ siteImage }) => {
          const facebookApp = "1130669443967303" // <========================= replace by env. variables ?
          const pageLanguage = "fr"
          const pageLocal = "fr_FR"
          const metaTitle = `${pageTitle} ∙ ${metadata.siteName}`
          const metaDescription = pageDescription || metadata.siteDescription
          const metaImage =
            (pageImage && "https:" + pageImage) ||
            (siteImage && "https:" + siteImage.cover.fluid.src)
          const metaImageWidth = "1920"
          const metaImageHeight = "1080"

          // console.log("SEO globalHistory.location", globalHistory.location)
          // const pageUrl = metadata.siteUrl + globalHistory.location.pathname

          return (
            <Helmet
              htmlAttributes={{
                lang: pageLanguage,
              }}
              title={truncate(metaTitle, 65)}
              // titleTemplate={`%s ∙ ${metadata.siteName}}
              link={[
                {
                  rel: "canonical",
                  href: pageUrl,
                },
                {
                  rel: "image_src",
                  href: metaImage,
                },
              ]}
              meta={[
                // Global metadata
                {
                  name: `description`,
                  content: truncate(metaDescription, 165),
                },
                {
                  name: `author`,
                  content: metadata.siteAuthor,
                },

                // Facebook Open Graph
                {
                  property: `og:site_name`,
                  content: metadata.siteName,
                },
                {
                  property: `og:title`,
                  content: truncate(metaTitle, 65),
                },
                {
                  property: `og:description`,
                  content: truncate(metaDescription, 297),
                },
                {
                  property: `og:type`,
                  content: `article`, // website | article
                },
                {
                  property: `og:url`,
                  content: pageUrl,
                },
                {
                  property: `og:image`,
                  content: metaImage,
                },
                {
                  property: `og:locale`,
                  content: pageLocal,
                },
                {
                  property: `og:image:width`,
                  content: metaImageWidth,
                },
                {
                  property: `og:image:height`,
                  content: metaImageHeight,
                },
                {
                  property: `fb:app_id`,
                  content: facebookApp,
                },

                // Twitter Cards
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:site`,
                  content: metadata.siteName,
                },
                {
                  name: `twitter:creator`,
                  content: metadata.siteAuthor,
                },
                {
                  name: `twitter:title`,
                  content: truncate(metaTitle, 70),
                },
                {
                  name: `twitter:description`,
                  content: truncate(metaDescription, 200),
                },
                {
                  name: `twitter:image`,
                  content: metaImage,
                },
              ]}
            >
              {/* STRUCTURED DATA */}
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "Article",
                  author: metadata.siteAuthor,
                  datePublished: "2020-01-01", // <================== UPDATE
                  headline: metaTitle,
                  image: metaImage,
                  publisher: "GusLyon.fr", // <================== UPDATE
                  url: pageUrl,
                  description: metaDescription,
                })}
              </script>
            </Helmet>
          )
        }}
      />
    )
  }
}

SEO.contextType = Context
