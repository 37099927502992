import React from "react"

export const TriangleTopRight = props => (
  <div className="uk-position-top-right">
    <div className="triangle-top-right uk-position-top-right" />
    <small
      className="uk-position-top-right uk-light uk-text-right"
      style={{ padding: "5px" }}
    >
      {props.Label}
    </small>
  </div>
)

export const TriangleBottomRight = props => (
  <div className="uk-position-bottom-right">
    <div className="triangle-bottom-right uk-position-bottom-right" />
    <small
      className="uk-position-bottom-right uk-padding-small uk-light uk-text-right"
      style={{ padding: "5px" }}
    >
      {props.Label}
    </small>
  </div>
)

export const TriangleTopLeft = props => (
  <div className="uk-position-top-left">
    <div className="triangle-top-left uk-position-top-left" />
    <small
      className="uk-position-top-left uk-padding-small uk-light uk-text-left"
      style={{ padding: "5px" }}
    >
      {props.Label}
    </small>
  </div>
)

export const TriangleBottomLeft = props => (
  <div className="uk-position-bottom-left">
    <div className="triangle-bottom-left uk-position-bottom-left" />
    <small
      className="uk-position-bottom-left uk-padding-small uk-light uk-text-left"
      style={{ padding: "5px" }}
    >
      {props.Label}
    </small>
  </div>
)
